.skeleton-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    -webkit-animation: 1s shine linear infinite;
    animation: 1s shine linear infinite;
    margin-top: 5px;
    margin-bottom: 5px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
