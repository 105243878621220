.comment-proof {
    table {
        width: 100%;

        tr {
            td {
                width: auto;
                padding: 10px;

                p {
                    margin: 8px 0 !important;
                }
            }

            td.head {
                width: 180px;
                font-weight: bold;
                text-align: center;
                background-color: #fae5d5;
            }
        }
    }
}
