.comment-list {
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    list-style: none;

    li {
        font-size: 15px;

        span {
            display: block;
            height: 25px;
            width: 25px;
            background-color: gainsboro;
            border: 2px solid grey;
            border-radius: 3px;
            text-align: center;
            margin-right: 5px;
        }

        &.active span {
            background-color: #ffff93;
            border: 2px solid #dbdb00;
        }
    }

    li + li {
        margin-top: 10px;
    }
}

.can-highlight {
    max-height: calc(100vh - 190px);
    overflow-y: auto;

    p {
        font-size: 14px;
    }
}