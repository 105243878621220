body {
    font-size: 0.75rem;
}

.form-control {
    font-size: 0.75rem;
    padding: 0.25rem;
    min-height: 32px !important;
}

.btn {
    font-size: 0.815rem;
    padding: 0.25rem;
    font-weight: 500;
}

.ag-icon-contracted,
.ag-icon-expanded {
    cursor: pointer;
}

.react-datepicker-popper {
    z-index: 10 !important;
}

.icon-briefcase {
    color: lightgreen !important;
}

.link-router-black {
    color: black;
}

.card-body {
    padding: 1rem 0 1rem 0;
}

.page-link,
#pageNumberInput .page-input {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    height: 32px !important;
}

.card-header {
    padding: 1.25rem 0.5rem 1.25rem 0.5rem;
}

.table th,
.table td {
    font-size: 0.75rem;
    padding: 0.25rem;
    vertical-align: middle;
}

.pagination {
    justify-content: flex-end;
}

button {
    border-radius: 2px !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block !important;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 50px !important;
    padding: 5px 10px !important;
}

.custom-tooltip {
    position: absolute;
    width: 250px;
    height: 150px;
    overflow: hidden;
    z-index: 500;
    pointer-events: none;
    background-color: #242424;
    transition: opacity 0.5s;
    padding: 10px;
}

.custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
}

.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
}

.custom-tooltip p:first-of-type {
    font-weight: bold;
}

.avatar-menu {
    right: auto;
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-132px, 35px, 0px);
}

.form-control {
    height: 32px;
    border-radius: 0;
    border-color: #9c9a9a;
}

.abscense-report .css-bg1rzq-control {
    // height: auto;
}

.css-16pqwjk-indicatorContainer,
.css-1thkkgx-indicatorContainer {
    padding: 4px !important;
}
.css-bg1rzq-control,
.css-14ojxsb-control,
.css-1szy77t-control {
    min-height: 32px !important;
    border-color: #9c9a9a !important;
    border-radius: 0.3rem !important;
}

.css-1hwfws3 {
    align-self: flex-start;
    padding: 2px 4px !important;
}

.schema-tr {
    display: inline-flex !important;
    flex-direction: column;
    width: 200px;
}

.schema-tr:hover::after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.075);
    left: 0;
}

.table-schema-header {
    padding: 10px;
    text-align: center;
    background-color: #f5f5f5;
}

.table-header-label {
    font-size: 1.2rem !important;
    font-weight: 500;
}

.header-wrapper {
    padding-left: 1.25rem;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-right: 1.25rem;
}

.form-group {
    margin-bottom: 0.25rem;
}

label {
    margin-bottom: 0.05rem;
}

.btn-link {
    font-size: 0.75rem;
    padding: 0;
    text-align: left;
    margin: auto;
    font-weight: 500;
    text-decoration: underline;
}

.grid-table {
    border-spacing: 5rem;
    border-collapse: collapse;
}

.link-router-black:hover {
    color: black;
}

.search-date {
    font-weight: bold;
    margin-left: 0;
}
.user-detail {
    width: 100%;
    .user-detail-label {
        font-size: 1.4rem;
    }
    .user-detail-btn {
        float: right;
    }
}
// ipWhitelist
.ipWhitelist {
    .ipWhitelist-alert {
        display: inline;
        margin-left: 10px;
    }
}
// user-entitlements-options
.user-entitlements-options {
    .btn-cancel {
        margin-right: 10px;
    }
}

.advance-area {
    margin: 0.5rem 0 0.5rem 0;
    .input-product-tag {
        width: 300px;
    }
    .select-brand {
        width: 300px;
        margin: 0.75rem 0 0 0;
        .css-bg1rzq-control {
            border-color: hsl(0, 5%, 92%);
        }
    }
    .date {
        width: 300px;
        margin: 0.5rem 0 0.5rem 0;
        height: 33px;
        .date-input {
            width: 145px;
        }
        .left {
            float: left;
        }
        .right {
            float: right;
        }
    }
    .date-label {
        width: 300px;
        height: 19px;
        margin: 0.5rem 0 0 0;
        font-weight: bold;
        .left {
            width: 155px;
            float: left;
        }
    }
}

.ok {
    background: transparent url(../src/assets/img/ok.png) no-repeat left center;
    background-size: 14px 14px;
    padding-left: 20px;
}

.dice {
    background: transparent url(../src/assets/img/dice.png) no-repeat left
        center;
    background-size: 20px 20px;
    padding-left: 20px;
}

.not-ok {
    background: transparent url(../src/assets/img/not-ok.png) no-repeat left
        center;
    background-size: 14px 14px;
    padding-left: 20px;
}

.cross-not-ok {
    background: transparent url(../src/assets/img/cross.png) no-repeat left
        center;
    background-size: 12px 12px;
    padding-left: 20px;
}

.edit {
    background: transparent url(../src/assets/img/pen.png) no-repeat left center;
    background-size: 20px 20px;
    padding-left: 20px;
}

.remove {
    background: transparent url(../src/assets/img/remove.png) no-repeat left
        center;
    background-size: 16px 16px;
    padding-left: 20px;
}
.check {
    background: transparent url(../src/assets/img/checkmar.png) no-repeat left
        center;
    background-size: 14px 14px;
    padding-left: 20px;
}
.star {
    background: transparent url(../src/assets/img/start.png) no-repeat left
        center;
    background-size: 12px 12px;
    padding-left: 20px;
}

.down {
    background: transparent url(../src/assets/img/down.png) no-repeat left
        center;
    background-size: 8px 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.right {
    background: transparent url(../src/assets/img/right.png) no-repeat left
        center;
    background-size: 8px 8px;
    padding-left: 5px;
    padding-right: 5px;
}
.del {
    background: transparent url(../src/assets/img/del-btn.PNG) no-repeat left
        center;
    background-size: 18px 18px;
    padding-left: 12px;
    padding-right: 8px;
}

.warning {
    color: #ce2700;
    background: transparent url(../src/assets/img/warning.png) no-repeat left
        center;
    font-size: 14px;
    padding-left: 20px;
}

.btn-create {
    float: right;
}
.title-small {
    font-size: 1rem;
    font-weight: bold;
}
.title-medium {
    font-size: 1.2rem;
    font-weight: bold;
}
.card-noborder {
    border: none;
}
.card-header-empty {
    border: none;
    background-color: #ffffff;
}

.schema-col:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 100%;
        *width: 100%;
    }
}
@media (min-width: 992px) {
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
        max-width: 14.285714285714285714285714285714% !important;
        flex: none !important;
    }
}

@media (min-width: 1200px) {
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
        max-width: 14.285714285714285714285714285714% !important;
        flex: none !important;
    }
}

//product detail
.no-mrg-bottom {
    margin-bottom: 0;
}
.mrg-left {
    margin-left: 2rem;
}
.mrg-top {
    margin-top: 1rem;
}
.alert-product-detail {
    margin: 1rem 2rem 0 2rem;
}

.course-select {
    min-width: 250px;
}

.reduced-padding-grid {
    padding-left: 0;
}

.reduced-padding-tabcontent .tab-pane {
    padding: 0.5rem;
}

.edit-user-add-course-btn {
    float: right;
    margin-right: 14px;
    width: 77px;
}

.text-bold {
    margin-left: 5px;
    font-weight: 600;
    font-style: italic;
}

.family-inline-group.css-1pcexqc-container {
    display: inline-block;
    width: 320px;
    margin: 0 10px;
}
.family-inline-group.form-group {
    float: right;
}
.clear-both {
    clear: both;
}
.link-adv {
    margin-top: 5px;
}
.label-align {
    margin-top: 8px;
    margin-right: 15px;
}
.head-col {
    white-space: nowrap;
}
.ag-header .ag-pinned-left-header .ag-header-cell-label {
    justify-content: left;
}
.abscense-report .ag-header .ag-header-cell-label {
    justify-content: center;
}
.text-min-height {
    min-height: 87px !important;
}

.schema-danceschool {
    background: #fff;
}
.schema-danceschool .grid-header {
    margin: -15px;
}
.schema-danceschool .grid-header .title {
    font-weight: bold;
    font-size: 18px;
}
.schema-danceschool .react-datepicker-wrapper,
.schema-danceschool .grid-pagination {
    display: inline-block !important;
}
.schema-danceschool .card-item {
    background: #7bb9ff;
    border-radius: 2px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.schema-danceschool .card-item:hover {
    box-shadow: 3px 3px #eee;
    border-bottom-color: #9c9a9a;
    border-right-color: #9c9a9a;
}

.dashboard .toast {
    box-shadow: none;
    max-width: 100% !important;
    margin: 5px 0 15px 0;
}

.dashboard .price-freely {
    height: 35px;
    max-width: 150px;
}
.dashboard .form-check-input {
    position: initial;
    margin-top: 0;
    margin-left: 0;
}

.email-module .label-form {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

textarea.minHeight {
    min-height: 196px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.floatingActions {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: white;
    border-top: 1px solid #ddd;
    // margin-top: 20px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f2f2f2;
    opacity: 1;
}

.answer-content img {
    max-width: 300px;
    max-height: 300px;
    height: auto;
}

.p-mb-0 p {
    margin-bottom: 0;
}

.p-mb-2 p {
    margin-bottom: 8px;
}

p:empty {
    display: none;
}

.btn-change-tab-spell {
    background-color: white;
    border: none;
}

.btn-change-tab-spell:hover,
.btn-change-tab-spell:active,
.btn-change-tab-spell:focus,
.btn-change-tab-spell:disabled {
    background-color: white;
    border: none;
    box-shadow: none;
}

// .word-replace:hover {
//     background-color: #4dbd74;
//     color: white;
//     cursor: pointer;
// }

.spelling-box {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
}

.end-0 {
    right: 0;
}

.tox-editor-header {
    z-index: 0 !important;
}

#table-questions td * {
    font-size: 0.75rem !important;
    text-decoration: unset !important;
    margin-bottom: 0;
}

.table-content{
    width: 40%;
}

.tox-tinymce {
    border: 1px solid #dedede !important;
    border-radius: 0.3rem !important;
    height: 350px !important;
}

.tox-editor-header {
    border-bottom: 1px solid #dedede !important;
}

#answer-list .tox-tinymce {
    height: 200px !important;
}

.group-category-select td {
    // padding-left: 10px;
    position: relative;
    border: 1px solid gray;
    vertical-align: top;
    overflow: auto;
}

.group-category-select ul {
    height: 300px;
    overflow: auto;
    padding: 0;
    margin: 0;
}

.group-category-select ul li {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    cursor: pointer;
}

.group-category-select.fancytree-ext-columnview span.fancytree-node {
    position: relative;
}

.group-category-select span.fancytree-node {
    display: inherit;
    width: 100%;
    margin-top: 0px;
    min-height: 20px;
    display: inline-flex;
    justify-content: space-between;
}

.group-category-select span.fancytree-custom-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 3px;
    background-position: 0px 0px;
}

.group-category-select .fancytree-plain span.fancytree-title {
    border: 1px solid transparent;
}

.group-category-select span.fancytree-title {
    color: black;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    min-height: 20px;
    padding: 0 3px 0 3px;
    margin: 0px 0 0 3px;
    border: 1px solid transparent;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
}

.group-category-select span.fancytree-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-image: url(https://cbt3.cyberschool.com.au/assets/fancytree/skin-win8/icons.gif);
    background-position: 0px 0px;
}

.group-category-select .fancytree-has-children span.fancytree-cv-right {
    // position: absolute;
    right: 3px;
    background-position: 0px -80px;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.group-category-select span.fancytree-node:hover span.fancytree-title {
    background-color: #eff9fe;
    border-color: #70c0e7;
}

.group-category-select .fancytree-has-children span.fancytree-cv-right:hover {
    background-position: -16px -80px;
}

.group-category-select span.fancytree-node.fancytree-expanded {
    background-color: #e0e0e0;
}

.group-category-select span.fancytree-node.fancytree-active {
    background-color: #cbe8f6;
}

#preview-question-page span {
    text-decoration: unset !important;
}

#table-questions img {
    display: none;
}

#table-questions table {
    display: none;
}

#table-questions *:not(i) {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 0.75rem !important;
}

#table-questions tr > td:first-child {
    font-weight: 400 !important;
}

#table-questions p:has(+ p > span > img) {
    display: none;
}

.choice-container {
    display: flex;
    [type="checkbox"] + label,
    [type="radio"] + label {
        position: relative;
        display: inline-block;
        min-height: 40px;
        padding: 5px 0 5px 60px;
        cursor: pointer;
    }

    p {
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 16px;
    }

    label::before {
        top: 20px;
        border-color: rgba(0, 0, 0, 0.3);
        content: "";
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 1px solid #ffffff;
        -webkit-box-shadow: inset 0 0 5.9px 0 rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset 0 0 5.9px 0 rgba(0, 0, 0, 0.3);
        -ms-box-shadow: inset 0 0 5.9px 0 rgba(0, 0, 0, 0.3);
        -o-box-shadow: inset 0 0 5.9px 0 rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 5.9px 0 rgba(0, 0, 0, 0.3);
        margin-right: 0.75em;
        left: 0;
        background: #fff;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        margin-top: -20px;
    }

    [type="checkbox"]:checked + label::before {
        background-color: #1798fa;
    }

    [type="checkbox"]:checked + label::after {
        top: 20px;
        content: "";
        position: absolute;
        width: 22px;
        height: 14px;
        background: 0 0;
        top: 50%;
        margin-top: -9px;
        left: 10px;
        border: 7px solid;
        border-top: none;
        border-right: none;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-color: #ffffff;
    }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: unset;
}

.cursor-pointer {
    cursor: pointer;
}

.invalid-field, .invalid-field .css-bg1rzq-control {
    border-color: #dc3545 !important;
}

.color-danger {
    color: #dc3545;
}

mn, mi, mo {
    font-size: 16px !important;
}

.form-control {
    border-color: #dedede;
    border-radius: 0.3rem;
}

.css-bg1rzq-control {
    border-color: #dedede !important;
    border-radius: 0.3rem !important;
}

.btn {
    height: 32px;
    border-radius: 0.3rem !important;
}

.modal-content {
    border: none;
    border-radius: 0.5rem;
}

.modal-header {
    border-color: #dedede;
}

.modal-footer {
    border-color: #dedede;
}

.question-to-test-option:hover {
    background-color: #70c0e7;
}

// .css-1szy77t-control {
//     box-shadow: 0 0 0 1px red;
// }

#task_instruction .tox-tinymce {
    height: 200px !important;
}