.CommentPlugin_CommentInputBox {
    display: block;
    // transform: translateX(-50%);
    position: fixed;
    width: 250px;
    min-height: 80px;
    background-color: #fff;
    box-shadow: 0 0 5px #0000001a;
    border-radius: 5px;
    z-index: 24;
    // animation: show-input-box 0.4s ease;

    .CommentPlugin_CommentInputBox_EditorContainer {
        position: relative;
        margin: 10px;
        border-radius: 5px;

        .CommentPlugin_CommentInputBox_Editor {
            position: relative;
            border: 1px solid #ccc;
            background-color: #fff;
            border-radius: 5px;
            font-size: 15px;
            caret-color: #050505;
            display: block;
            padding: 9px 10px 10px 9px;
            min-height: 80px;

            .CommentEditorTheme__paragraph {
                margin: 0;
            }
        }

        .Placeholder__root {
            font-size: 15px;
            color: #999;
            overflow: hidden;
            position: absolute;
            text-overflow: ellipsis;
            top: 8px;
            left: 28px;
            right: 28px;
            user-select: none;
            white-space: nowrap;
            display: inline-block;
            pointer-events: none;
        }
    }

    .CommentPlugin_CommentInputBox_Buttons {
        display: flex;
        flex-direction: row;
        padding: 0 10px 10px;
        gap: 10px;

        .CommentPlugin_CommentInputBox_Button {
            flex: 1;
        }

        .Button__root {
            padding: 10px 15px;
            border: 0px;
            background-color: #eee;
            border-radius: 5px !important;
            cursor: pointer;
            font-size: 14px;
        }

        .CommentPlugin_CommentInputBox_Button.primary {
            background-color: #4287f5;
            font-weight: 700;
            color: #fff;
        }

        .CommentPlugin_CommentInputBox_Button[disabled] {
            background-color: #eee;
            opacity: 0.5;
            cursor: not-allowed;
            font-weight: 400;
            color: #444;
        }
    }
}

// .CommentPlugin_CommentInputBox:before {
//     content: "";
//     position: absolute;
//     width: 0;
//     height: 0;
//     margin-left: -8.5em;
//     right: -1em;
//     top: 0;
//     left: calc(50% + 0.25em);
//     box-sizing: border-box;
//     border: 0.5em solid black;
//     border-color: transparent transparent #fff #fff;
//     transform-origin: 0 0;
//     transform: rotate(135deg);
//     box-shadow: -3px 3px 3px #0000000d;
// }

@keyframes show-input-box {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
