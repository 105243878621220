.preview-question {
    background-color: #b8c3cc;
    position: relative;

    .main-content {
        background: #fff;
        height: calc(100vh - 55px);
    }

    .bg-image {
        background: url(/logo_450.png) center center no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 450px 450px;
        opacity: 0.1;
        pointer-events: none;
        z-index: 1;
    }

    .right-arrow {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -25px;
        margin-left: -1px;
        margin-right: -1px;
        height: 50px;
        width: 30px;
        cursor: pointer;
        z-index: 9;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(102, 102, 102, 0.25);
        border-radius: 5px 0 0 5px;
        background: rgba(255, 255, 255, 0.6);

        & > div {
            display: block;
            top: 325px;
            left: 0;
            margin-top: 14px;
            margin-left: 10px;
            height: 20px;
            width: 20px;
            cursor: pointer;
            z-index: 9999;
            border-width: 10px;
            transform: rotate(135deg);
            border-style: solid;
            border-color: transparent #1798fa #1798fa transparent;
        }
    }

    .article-section {
        text-align: justify;
        overflow-y: scroll;
        padding: 60px;
        height: calc(100vh - 115px);
        position: relative;
        z-index: 2;
    }

    .question-content-section {
        padding: 60px;
        position: relative;
        height: calc(100vh - 115px);
        overflow-y: auto;
    }

    .checklist-section{
        padding: 60px 16px;
        height: calc(100vh - 115px);
        position: relative;
        overflow-y: auto;
    }

    .left-arrow {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -25px;
        margin-left: -1px;
        margin-right: -10px;
        height: 50px;
        width: 30px;
        cursor: pointer;
        z-index: 9;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(102, 102, 102, 0.25);
        border-radius: 0 5px 5px 0;
        background: rgba(255, 255, 255, 0.6);

        & > div {
            display: block;
            top: 325px;
            left: 0;
            margin-top: 14px;
            margin-left: -3px;
            margin-right: -10px;
            height: 20px;
            width: 20px;
            cursor: pointer;
            z-index: 9999;
            border-width: 10px;
            transform: rotate(-45deg);
            border-style: solid;
            border-color: transparent #1798fa #1798fa transparent;
        }
    }

    .btn-view-solution {
        background-color: rgb(225, 238, 249);
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
    }

    .button-foot {
        font-size: 20px;
        border-radius: 0;
        font-weight: 700;
        padding: 15px 30px;
        color: #333;
        background-color: #fff;
        border: 1px solid #ccc;
    }

    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .margin-t{
        margin-top: 3px;
    }

    .checklist{
        z-index: 10;
        width: 500px;
        min-height: 200px;
        position: absolute;
        top: 0;
        right: 0;
        // border: 1px solid 
        &__header{
           min-height: 60px; 
        }
        &__footer{
            min-height: 60px;
        }
        &__btn{
            font-size: 14px;
            min-width: 100px;
            color: white;
            &--primary{
                background-color: #0d6efd;
            }
            &--secondary{
                background-color: #6c757d;
            }
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .close {
        cursor: pointer;
        font-size: 30px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4px;
      }
    .font14{
        font-size: 14px;
    }
}
